html,
body {
  height: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
  width: 100%;
}
#root {
  height: 100%;
}
.cat-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.boldText {
  font-weight: bold !important;
}

.verticalText {
  font-size: 10px !important;
  // writing-mode: sideways-lr; // veikia tik Firefox
  writing-mode: vertical-rl; // tada geriau naudoti sita + transform 180
  transform: rotate(180deg);
  padding: 5px 1px 1px 1px !important;
}
.MuiDataGrid-columnHeaderWrapper {
  align-items: end !important;
}
.reportMark {
  text-align: center !important;
  padding: 1px !important;
}
.MuiButton-containedPrimary {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.redBakground {
  background-color: red;
}
.small-letters-cell {
  font-size: 0.7rem;
}
.topControlRow {
  margin: 5px 0px 5px 0px;
}
.modal-outer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-inner {
  max-height: 80vh;
  padding: 0px 50px;
  overflow-y: scroll !important;
}

// TablePR2; FiltersPR; DashboardPR //
.tableRowSmall th,
.tableRowSmall td {
  padding: 6px 16px 6px 16px !important;
}
.tableRowSmall a,
.tableRowSmall button {
  padding: 0px !important;
}
.numbers {
  font-size: 12px !important;
  text-align: right !important;
}
.total {
  text-align: right !important;
}
.compact {
  padding: 5px !important;
}
.marginTop {
  margin-top: 5px;
}
.divider_cards {
  margin: 10px 0px !important;
}
.tableHdVert {
  font-size: 10px !important;
  padding: 0px !important;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
.formControlTop {
  align-items: center;
  display: flex;
}
.formControl {
  padding: 6px;
  margin-left: 5px !important;
}
.formControlText {
  padding: 6px;
  display: inline-flex;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.errorBox {
  width: 20px;
  height: 20px;
  margin: 6px;
}
.errorText {
  border-right: 1px solid black;
  padding-right: 6px;
  text-align: center;
}
.metricCard {
  margin-top: 10px;
}
.reportTitle {
  display: inline-block;
  margin-right: 25px !important;
  font-weight: bold !important;
}
.totalWithUnknowns {
  color: red;
}

.headerDashboardPR {
  font-size: 10px !important;
  text-align: center;
  padding: 0px !important;
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}

.cellDashboardPR {
  text-align: center !important;
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}

.top-row-cell {
  line-height: 16px;
  text-align: center;
}
.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
  padding: 0px !important;
}