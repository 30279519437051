html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

#root {
  height: 100%;
}

.cat-container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.boldText {
  font-weight: bold !important;
}

.verticalText {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  padding: 5px 1px 1px !important;
  font-size: 10px !important;
}

.MuiDataGrid-columnHeaderWrapper {
  align-items: end !important;
}

.reportMark {
  text-align: center !important;
  padding: 1px !important;
}

.MuiButton-containedPrimary {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.redBakground {
  background-color: red;
}

.small-letters-cell {
  font-size: .7rem;
}

.topControlRow {
  margin: 5px 0;
}

.modal-outer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.modal-inner {
  max-height: 80vh;
  padding: 0 50px;
  overflow-y: scroll !important;
}

.tableRowSmall th, .tableRowSmall td {
  padding: 6px 16px !important;
}

.tableRowSmall a, .tableRowSmall button {
  padding: 0 !important;
}

.numbers {
  text-align: right !important;
  font-size: 12px !important;
}

.total {
  text-align: right !important;
}

.compact {
  padding: 5px !important;
}

.marginTop {
  margin-top: 5px;
}

.divider_cards {
  margin: 10px 0 !important;
}

.tableHdVert {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  padding: 0 !important;
  font-size: 10px !important;
}

.formControlTop {
  align-items: center;
  display: flex;
}

.formControl {
  padding: 6px;
  margin-left: 5px !important;
}

.formControlText {
  text-transform: uppercase;
  text-align: center;
  padding: 6px;
  font-weight: bold;
  display: inline-flex;
}

.errorBox {
  width: 20px;
  height: 20px;
  margin: 6px;
}

.errorText {
  text-align: center;
  border-right: 1px solid #000;
  padding-right: 6px;
}

.metricCard {
  margin-top: 10px;
}

.reportTitle {
  display: inline-block;
  margin-right: 25px !important;
  font-weight: bold !important;
}

.totalWithUnknowns {
  color: red;
}

.headerDashboardPR {
  text-align: center;
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
  padding: 0 !important;
  font-size: 10px !important;
}

.cellDashboardPR {
  text-align: center !important;
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}

.top-row-cell {
  text-align: center;
  line-height: 16px;
}

.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
  padding: 0 !important;
}

/*# sourceMappingURL=index.4808c856.css.map */
